import React from 'react'
import { jsx } from '@emotion/core'
import { theme } from '../../../configs/accdotcom.theme'
import { ThemeProvider } from 'emotion-theming'
import { Image } from '../../../components'
import {
  Body,
  TitleLarge,
  TitleMedium,
  TitleSmall,
  UnorderedList,
  UnorderedListItem,
  FootNote,
} from '../../../components/RichText'
import SEO from '../../../templates/Layout/SEO'

import { Accordions, Accordion } from '../../../components/Accordion'
import { mq } from '../../../hooks'

const accordionsData_General = [
  {
    header: [
      {
        type: 'TitleMedium',
        children: [{ text: 'What is the TRICARE Select Navigator program?' }],
      },
    ],
    content: [
      {
        type: 'Body',
        children: [
          {
            text:
              'TRICARE Select Navigator is administered by Accolade — a confidential health benefits service that helps make navigating your health care easier and less stressful — at no additional cost to you. Eligible beneficiaries can ask a nurse for help with health benefits questions, big or small.',
          },
        ],
      },
    ],
  },
  {
    header: [
      {
        type: 'TitleMedium',
        children: [
          { text: 'What services does TRICARE Select Navigator offer?' },
        ],
      },
    ],
    content: [
      {
        type: 'Body',
        children: [
          {
            text:
              'TRICARE Select Navigator gives you or your family member the help you need to make better health decisions, navigate a complex health care system, and get the most from your benefits.',
          },
        ],
      },
      {
        type: 'Body',
        children: [
          {
            text: 'TRICARE Select Navigator helps eligible beneficiaries:',
          },
        ],
      },
      {
        type: 'UnorderedList',
        children: [
          {
            type: 'UnorderedListItem',
            children: [
              {
                text:
                  'Find a quality Network provider specialist who is right for you',
              },
            ],
          },
          {
            type: 'UnorderedListItem',
            children: [
              {
                text: 'Get health care set up after a move',
              },
            ],
          },
          {
            type: 'UnorderedListItem',
            children: [
              {
                text: 'Answer benefit questions and coordinate care',
              },
            ],
          },
          {
            type: 'UnorderedListItem',
            children: [
              {
                text: 'Schedule and prepare for an appointment',
              },
            ],
          },
          {
            type: 'UnorderedListItem',
            children: [
              {
                text: 'Prepare for a test, procedure or hospital stay',
              },
            ],
          },
          {
            type: 'UnorderedListItem',
            children: [
              {
                text: 'Understand a medical bill, and more',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    header: [
      {
        type: 'TitleMedium',
        children: [
          {
            text:
              'Is TRICARE Select Navigator available to all TRICARE Select beneficiaries?',
          },
        ],
      },
    ],
    content: [
      {
        type: 'Body',
        children: [
          {
            text:
              'The TRICARE Select Navigator program is available to TRICARE Select beneficiaries who meet certain criteria such as: ',
          },
          {
            type: 'UnorderedList',
            children: [
              {
                type: 'UnorderedListItem',
                children: [
                  {
                    text:
                      'You have two or more complex medical conditions. These may include illnesses such as asthma, cancer, depression, diabetes, and heart disease.',
                  },
                ],
              },
              {
                type: 'UnorderedListItem',
                children: [
                  {
                    text:
                      'You have claims totaling $100,000 a year or more. This may happen if you have multiple emergency room trips or hospital admissions.',
                  },
                ],
              },
              {
                type: 'UnorderedListItem',
                children: [
                  {
                    text: 'Call ',
                  },
                  {
                    type: 'link',
                    url: 'tel:8334009603',
                    textStyle: { color: 'blue' },
                    children: [
                      {
                        text: '833-400-9603',
                      },
                    ],
                  },
                  {
                    text:
                      ' to get started. Individuals choose how much assistance they wish to receive from a TRICARE Select Navigator nurse.',
                  },
                ],
              },
            ],
          },
          {
            text: '* TRICARE Select Navigator is not available overseas.',
          },
        ],
      },
    ],
  },
  {
    header: [
      {
        type: 'TitleMedium',
        children: [{ text: 'Is there an extra cost for this service?' }],
      },
    ],
    content: [
      {
        type: 'Body',
        children: [
          {
            text:
              'No. TRICARE Select Navigator, administered by Accolade, is provided at no additional cost to eligible beneficiaries.',
          },
        ],
      },
    ],
  },
].map(block => <Accordion data={block} />)

const accordionsData_UsingAccolade = [
  {
    header: [
      {
        type: 'TitleMedium',
        children: [{ text: 'Will I be assigned a dedicated Accolade nurse?' }],
      },
    ],
    content: [
      {
        type: 'Body',
        children: [
          {
            text:
              'Yes. You’ll connect with an Accolade nurse the first time you call. During that call, the nurse will let you know that they are your dedicated nurse. They will also give you their direct phone extension to make calling them easier next time.',
          },
        ],
      },
    ],
  },
  {
    header: [
      {
        type: 'TitleMedium',
        children: [{ text: 'Can I talk only to my assigned Accolade nurse?' }],
      },
    ],
    content: [
      {
        type: 'Body',
        children: [
          {
            text:
              'No. If your dedicated Accolade nurse is not available, you can leave them a voice message or choose to speak to another available nurse. When you send a secure message, your Frontline Care Team is available for quick responses, too.',
          },
        ],
      },
    ],
  },
  {
    header: [
      {
        type: 'TitleMedium',
        children: [{ text: 'Can I switch Accolade nurses?' }],
      },
    ],
    content: [
      {
        type: 'Body',
        children: [
          {
            text:
              'Yes. Just ask and you’ll be assigned a different Accolade nurse.',
          },
        ],
      },
    ],
  },
  {
    header: [
      {
        type: 'TitleMedium',
        children: [{ text: 'Will Accolade nurses ever contact me directly?' }],
      },
    ],
    content: [
      {
        type: 'Body',
        children: [
          {
            text:
              'Yes. Accolade nurses may check in with you to make sure you are doing well and are getting the care you need. They may reach out to you to follow up on an earlier conversation. For example, your nurse may connect with you to ask about an office visit with the new doctor they helped you choose.',
          },
        ],
      },
    ],
  },
  {
    header: [
      {
        type: 'TitleMedium',
        children: [{ text: 'How and when can I contact Accolade?' }],
      },
    ],
    content: [
      {
        type: 'Body',
        children: [
          {
            text: 'Call ',
          },
          {
            type: 'link',
            url: 'tel:8334009603',
            textStyle: { color: 'blue' },
            children: [
              {
                text: '833-400-9603',
              },
            ],
          },
          {
            text:
              ' Monday through Friday, 8AM to MIDNIGHT ET. If you need to reach someone outside normal business hours, ',
          },
          {
            type: 'link',
            url: 'https://tricare.mil/NAL',
            textStyle: { color: 'blue' },
            children: [{ text: "TRICARE's 24-hour Nurse Advice Line" }],
          },
          {
            text:
              'will be available at the same number (but please dial 911 if it is an emergency).',
          },
        ],
      },
    ],
  },
  {
    header: [
      {
        type: 'TitleMedium',
        children: [{ text: 'Can Accolade provide support if I’m traveling?' }],
      },
    ],
    content: [
      {
        type: 'Body',
        children: [
          {
            text:
              'Yes. Beneficiaries can ask Accolade for help understanding available coverage while traveling in or outside of the United States. If you need to find a provider or care facility overseas, Accolade will coordinate with your regional contractor to obtain the appropriate information.',
          },
        ],
      },
    ],
  },
].map(block => <Accordion data={block} />)

const accordionsData_privacy = [
  {
    header: [
      {
        type: 'TitleMedium',
        children: [{ text: 'How is my privacy protected?' }],
      },
    ],
    content: [
      {
        type: 'Body',
        children: [
          {
            text:
              'To provide a highly personalized and confidential service for eligible beneficiaries, Accolade is committed to protecting the privacy and security of all personal information. Accolade is independent of TRICARE and all health insurance companies. To protect your privacy, Accolade uses a modern data security infrastructure, tested security procedures, and extensive training to ensure that all personal information is shared only within federal guidelines and only with your permission. In addition to many other security measures, Accolade uses an identity verification process when members call and access member.accolade.com or the Accolade mobile app.',
          },
        ],
      },
    ],
  },
  {
    header: [
      {
        type: 'TitleMedium',
        children: [
          {
            text:
              'Does Accolade comply with federal privacy laws such as HIPAA?',
          },
        ],
      },
    ],
    content: [
      {
        type: 'Body',
        children: [
          {
            text:
              'Yes. Accolade is fully compliant with The Health Insurance Portability and Accountability Act (HIPAA) and all applicable federal and state privacy laws.',
          },
        ],
      },
    ],
  },
].map(block => <Accordion data={block} />)

export default () => {
  return (
    <>
      <SEO
        title="TRICARE Select® Navigator"
        description="Understand the terms of use for Accolade services and products."
      />
      <ThemeProvider theme={theme}>
        <div
          css={mq({
            maxWidth: '900px',
            margin: ['0 1.5rem', '0 3rem', '0 12rem', 'auto'],
            marginTop: '2rem',
            marginBottom: '2rem',
          })}
        >
          <div css={{ marginLeft: '-30px', maxWidth: '400px' }}>
            <Image data={{ path: '011dya9iqrho.png' }} />{' '}
          </div>{' '}
          <section>
            <TitleLarge>
              Personalized help with TRICARE Select® Navigator, administered by
              Accolade{' '}
            </TitleLarge>{' '}
            <Body>
              Figuring out how to manage a health issue or which doctor to see
              isn't always easy. Add in frequent moves and the need to find new
              health care providers, the difficulty only increases. With&nbsp;
              <a
                href="https://tricare.mil/Plans/SpecialPrograms/SelectNavigator"
                target="_blank"
                style={{ color: 'blue' }}
              >
                TRICARE Select Navigator
              </a>
              ,&nbsp;administered by Accolade, beneficiaries get help from a
              nurse who takes the time to get to know them and their family.{' '}
            </Body>{' '}
            <Body>
              <strong> Get started today.</strong> Call&nbsp;
              <a href="tel:8334009603" style={{ color: 'blue' }}>
                833-400-9603
              </a>
              . Monday through Friday, 8 AM to MIDNIGHT ET. Follow us on&nbsp;
              <a
                href="https://www.facebook.com/AccoladeGS"
                target="_blank"
                style={{ color: 'blue' }}
              >
                Facebook
              </a>{' '}
              to stay up to date with the latest news.
            </Body>{' '}
          </section>{' '}
          <section id="FaqSection">
            <div css={{ marginTop: '3rem' }}>
              <TitleLarge> GENERAL QUESTIONS </TitleLarge>{' '}
              <Accordions> {accordionsData_General} </Accordions>{' '}
            </div>{' '}
            <div css={{ marginTop: '3rem' }}>
              <TitleLarge>
                USING THE TRICARE SELECT NAVIGATOR PROGRAM{' '}
              </TitleLarge>{' '}
              <Accordions> {accordionsData_UsingAccolade} </Accordions>{' '}
            </div>{' '}
            <div css={{ marginTop: '3rem' }}>
              <TitleLarge> PRIVACY AND CONFIDENTIALITY </TitleLarge>{' '}
              <Accordions> {accordionsData_privacy} </Accordions>{' '}
            </div>{' '}
          </section>{' '}
          <section
            css={{
              marginTop: '3rem',
            }}
          >
            <FootNote>
              TRICARE is a registered trademark of the Department of Defense,
              Defense Health Agency. All rights reserved. <br />
              Accolade does not practice medicine or provide patient care. We are
              an independent resource to support and assist you as you use the
              health care system and receive medical care from your own doctors,
              nurses, and health care professionals. If you have a medical
              emergency, please contact 911 immediately.{' '}
            </FootNote>{' '}
          </section>{' '}
        </div>{' '}
      </ThemeProvider>
    </>
  )
}
